<template>
<div>
    <v-dialog
    v-model="dialog"
    max-width="800"
    scrollable
  >
    <v-card>
      <v-card-title>
        <span>
          Movimientos Bancarios para la Liquidacion <strong>{{ datos.liqId }}</strong>
        </span>
        <v-btn 
          title="Agregar Mov. Bancario" @click="agregNvoMov()"
          icon color="success" small fab
        >
          <v-icon> fas fa-plus-circle </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn title="Cerrar" icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <div class="pl-5 pt-4">Total de la Liquidación: {{ formatMoney(totalLiq) }} - Total Acumulado: {{ formatMoney(parseFloat(totalAcumulado/*.toFixed(2)*/)) }} - Pendiente: {{ formatMoney(parseFloat(pendiente/*.toFixed(2)*/)) }}</div>
      <v-spacer></v-spacer>
      <v-card-text>
        <v-row class="">
          <v-col cols="12" class="pa-0">
            <v-data-table
              class="cebra elevation-1 mt-2"
              :headers="headers"
              :items="movimientos"
              :loading="load_2"
              :search="search"
              hide-default-footer
              dense
            >
              <template v-slot:top>
                <v-row class="d-flex justify-end pa-2" no-gutters>
                  <v-col cols="6" sm="3" md="2">
                    <SearchDataTableVue
                      v-model="search"
                    />
                  </v-col>
                </v-row>
              </template>
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto mt-4"
                  max-width="400"
                  type="warning"
                  border="left"
                  dense
                  text
                >
                  No hay datos para los filtros seleccionados
                </v-alert>
              </template>
              <template v-slot:[`item.acciones`]="{ item }">
                <v-icon
                  color="error"
                  title="Eliminar"
                  small
                  @click="eliminar_mov(item)"
                >
                  fas fa-times-circle
                </v-icon>
                <v-icon
                class="ml-2"
                  color="indigo"
                  title="Editar"
                  small
                  @click="editar_mov(item)"
                >
                  fas fa-pen
                </v-icon>
              </template>
              <template
                v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
                v-slot:[`item.${header.value}`]="{ value }"
              >
                {{ header.formatter(value) }}
              </template>
              <template slot="body.append">
                <tr class="font-weight-bold">
                  <th style="font-size: 1em" colspan="2"> Total:</th>
                  <th style="font-size: 1em">{{ formatMoney(retornaAcuMov) }}</th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </template>
              <template v-slot:[`item.fechas`]="{item}">
                {{ item.tipo_movimiento != 3 ? item.fecha : item.fecha_emision }}
              </template>
            </v-data-table> 
          </v-col>
          <v-col cols="12" class="pa-0 pt-10" v-if="movimientos_a.length > 0">
            <span style="font-size: 1.2em; font-weight:bold;">Movimientos Bancarios a Anular</span>
            <v-data-table
              class="cebra elevation-1 mt-2"
              :headers="headers"
              :items="movimientos_a"
              hide-default-footer
              dense
            >
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto mt-4"
                  max-width="400"
                  type="warning"
                  border="left"
                  dense
                  text
                >
                  No hay datos para los filtros seleccionados
                </v-alert>
              </template>
              <template v-slot:[`item.fechas`]="{item}">
                {{ item.tipo_movimiento != 3 ? item.fecha : item.fecha_emision }}
              </template>
              <template v-slot:[`item.acciones`]="{ item }">
                <v-icon
                  color="success"
                  title="Revivir movimiento"
                  small
                  @click="revivirMov(item)"
                >
                  fas fa-check
                </v-icon>
              </template>
              <template
                v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
                v-slot:[`item.${header.value}`]="{ value }"
              >
                {{ header.formatter(value) }}
              </template>
            </v-data-table> 
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          class="mr-2"
          color="error"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmarVue
          :loading="load"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>

  </v-dialog>
  <ModalAgregarEditarMovBancVue
    v-model="dialog_AM"
    :datos="movBancObj"
    @agregarMov="agregarNvoMovDelModal"
    @editarMov="editarMovDelModal"
  />
</div>
</template>

<script>
import { format_money, roundNumber } from '../../util/utils'
import BtnConfirmarVue from '../util/BtnConfirmar.vue'
import SearchDataTableVue from '../util/SearchDataTable.vue'
import ModalAgregarEditarMovBancVue from './ModalAgregarEditarMovBanc.vue'
import moment from 'moment'
import { order_list_by } from '../../util/utils'

export default {
  name: 'ModalMovBancLiq',
  data(){
    return{
      moment: moment,
      formatMoney: format_money,
      load: false,
      load_2:false,
      totalLiq: null,
      totalAcumulado: 0,
      totalAcuCopia: 0,
      pendienteCopia: 0,
      pendiente: 0,
      search: '',
      dialog_AM: false,
      movBancObj: {},
      movimientos_a:[],
      movimientos_aCopia:[],
      fechaAux: null,
      //getMovsMultiples
      movimientos:[],
      idInterno: 0,
      bancos:[],
      formatosCheques: [],
      tiposCheques: [],
      tiposMovBanco: [],
      bancosPais: [],
      //DataTable
      headers:[
        { text: 'Mov. Código', value: 'comprobante_id' },
        { text: 'Tipo', value: 'tipo_movimiento_nom' },
        { text: 'Importe', value: 'importe', formatter: format_money  },
        { text: 'Fecha', value: 'fechas' },
        { text: 'Auditado', value: 'auditado_nom', align:'center', },
        { text: 'Acciones', value: 'acciones', align:'center', sorteable: false, filtreable: false},
      ]
    }
  },
  props:{
    value: Boolean,
    datos: {
      type: Object,
      default: {
        liqId: null,
        totalNeto: null
      }
    },
  },
  created(){
  },
  methods:{
    async iniciarModal(){
      this.$store.state.loading = true;
      this.load_2 = true;
      this.totalLiq = this.datos.totalNeto; //this.totalLiq = parseFloat(this.totalLiq.toFixed(2));
      await this.$store.dispatch('conciliacionManual/getMovMultiples', this.datos.liqId)
      .then((res) => {
        this.movimientos = res.movimientos;
        for(let i = 0; i < this.movimientos.length ; i++){
          this.movimientos[i].idInterno = this.idInterno;
          this.idInterno++;
        }
        this.bancos = res.bancos; order_list_by(this.bancos, 'banco_nombre');
        this.formatosCheques = res.formatos_cheques;
        this.tiposCheques = res.tipos_cheques;
        this.tiposMovBanco = res.tipos_mov_banco; 
        this.bancosPais = res.bancos_pais;
      })
      .catch(error => {
        this.$store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      })
      this.load_2 = false;
      this.$store.state.loading = false;
    },
    eliminar_mov(item){
      if(item.comprobante_id != 0){
        if(item.auditado == 1){ //si está auditado entonces no deja borrar.
          this.$swal.fire({
            icon: 'warning',
            title: `Editar Movimiento Bancario ${item.comprobante_id}.`,
            text: 'El movimiento seleccionado se encuentra auditado. No se puede quitar.'
          })
          return
        }
        let aux_importe = item.importe;
        let codigo_item = item.comprobante_id;
        this.pendiente += aux_importe;    this.pendiente = parseFloat(this.pendiente.toFixed(2));
        this.totalAcumulado -= aux_importe;   this.totalAcumulado = parseFloat(this.totalAcumulado.toFixed(2));
        //const index = this.movimientos.map("comprobante_id").indexOf(codigo_item);
        this.movimientos = this.movimientos.filter(elem => elem.comprobante_id != codigo_item);
        this.movimientos_a.push(item);
      }else{
        const index = this.movimientos.indexOf(item)
        this.movimientos.splice(index,1)
        this.pendiente += item.importe;   this.pendiente = parseFloat(this.pendiente.toFixed(2));
        this.totalAcumulado -= item.importe; this.totalAcumulado = parseFloat(this.totalAcumulado.toFixed(2));
        //this.movimientos = this.movimientos.filter(elem => elem.comprobante_id != 0);
      }
    },
    //prepara el objeto para abrir el modal
    editar_mov(item){
      this.movBancObj={
        liqId: this.datos.liqId,
        editar: true,
        fecha: item.fecha,
        fecha_emision : item.fecha_emision,
        fecha_vencimiento : item.fecha_vencimiento,
        totalLiq: this.totalLiq,
        totalAcu: this.totalAcumulado,
        comprobante_id: item.comprobante_id,
        id_interno: item.id_interno,
        //editar es true:
        tipo_mov: item.tipo_movimiento,
        importe: item.importe,
        banco: item.banco_codigo ,
        banco_pais: item.banco_pais,
        cuenta: item.banco_cuenta,
        formato: item.formato,
        tipoCheque: item.tipo_cheque,
        beneficiario: item.beneficiario,
        beneficiario_cuit: item.beneficiario_cuit,
        //fin
        tiposMovBanc: this.tiposMovBanco,
        bancos: this.bancos,
        bancosPais: this.bancosPais,
        tiposCheques: this.tiposCheques,
        formatosCheques: this.formatosCheques,
        numero_comprobante: item.numero_comprobante
      }
      this.dialog_AM = true;
    },
    //si ya es un movimiento, es decir tiene código != 0 entonces al eliminar luego te deja revivir
    revivirMov(item){
      if(item.importe + this.totalAcumulado > this.totalLiq){
        this.$swal.fire({
          icon: 'warning',
          title: `Reviviendo Movimiento Bancario`,
          text: 'No se puede revivir este movimiento ya que sobrepasaría el total de la liquidación.',
        })
      }else{
        this.movimientos_a = this.movimientos_a.filter(elem => elem.comprobante_id != item.comprobante_id);
        this.movimientos.push(item)
        this.pendiente -= item.importe;     this.pendiente = parseFloat(this.pendiente.toFixed(2));
        this.totalAcumulado += item.importe;  this.totalAcumulado = parseFloat(this.totalAcumulado.toFixed(2));
      }
    },
    //prepara el objeto que se mandará al modal
    agregNvoMov(){
      if(this.totalLiq == this.totalAcumulado){
        this.$swal.fire({
          icon: 'info',
          title: `Agregar un nuevo Movimiento Bancario`,
          text: 'El Total Acumulado ya coincide con el Total de la Liquidación. No se puede agregar.',
        })
      }else{
        this.movBancObj={
          liqId: this.datos.liqId,
          editar: false,
          fecha: moment(new Date()).format('DD/MM/YYYY'),
          totalLiq: this.totalLiq,
          totalAcu: this.totalAcumulado,
          //editar es false:
          tipo_mov: null,
          importe: null,
          banco: null ,
          banco_pais: null,
          cuenta: null,
          formato: null,
          tipoCheque: null,
          beneficiario: null,
          beneficiario_cuit: null,
          //fin
          tiposMovBanc: this.tiposMovBanco,
          bancos: this.bancos,
          bancosPais: this.bancosPais,
          tiposCheques: this.tiposCheques,
          formatosCheques: this.formatosCheques,
          numero_comprobante: this.datos.liqId
        };
        this.dialog_AM = true;
      }
    },
    //es el método que se ejecturá cuando se haga el emit en el componente hijo y efectivamente agrega uno nuevo
    agregarNvoMovDelModal(item){
      this.movimientos.push({
        "banco_codigo": item.banco,
        "banco_cuenta": item.cuenta,
        "banco_pais": item.banco_pais,
        "beneficiario": item.beneficiario,
        "beneficiario_cuit": item.beneficiario_cuit,
        "comprobante_id": 0,
        //"tipo_comprobante": 126,
        "emisor": null,
        //"empresa_codigo": 0,
        "fecha": item.tipo_mov != 3 ? item.fecha : item.fecha_emision,
        "fecha_emision": item.tipo_mov != 3 ? null : item.fecha_emision,
        "fecha_vencimiento": item.tipo_mov != 3 ? null : item.fecha_vencimiento,
        "formato": item.tipo_mov != 3 ? 0 : item.formato_cheque,
        "importe": item.importe,
        //"interes": 0,
        "numero_comprobante": item.num_comp,
        "origen_deposito": 4,
        //"referencia": null,
        "tipo_cheque": item.tipo_mov != 3 ? 0 : item.tipo_cheque,
        "tipo_movimiento": item.tipo_mov,
        "tipo_movimiento_nom": this.tiposMovBanco.filter(elem => elem.tipo_mov_codigo == item.tipo_mov)[0].tipo_mov_nombre,
        "auditado": 0,
        "auditado_nom": "NO",
        "id_interno": this.idInterno,
      });
      this.idInterno++;
      this.dialog_AM = false;
      this.totalAcumulado += item.importe;    //this.totalAcumulado = parseFloat(this.totalAcumulado.toFixed(2));
      this.pendiente = this.totalLiq - this.totalAcumulado;   //this.pendiente = parseFloat(this.pendiente.toFixed(2));
    },
    //determina la posición del que se va a editar y lo edita
    editarMovDelModal(item){
      //return
      let i = 0;
      let tamañoMovimientos = this.movimientos.length;
      while(i < tamañoMovimientos && this.movimientos[i].id_interno != item.id_interno){
        i++;
      }
      if(i < tamañoMovimientos){
        this.movimientos[i].banco_codigo = item.banco;
        this.movimientos[i].banco_pais = item.banco_pais;
        this.movimientos[i].beneficiario = item.beneficiario;
        this.movimientos[i].beneficiario_cuit = item.beneficiario_cuit;
        this.movimientos[i].comprobante_id = item.comprobante_id;
        this.movimientos[i].banco_cuenta = item.cuenta;
        this.movimientos[i].emisor = item.emisor;
        this.movimientos[i].fecha = item.fecha;
        this.movimientos[i].fecha_emision = item.fecha_emision;
        this.movimientos[i].fecha_vencimiento = item.fecha_vencimiento;
        this.movimientos[i].formato = item.formato_cheque;
        this.movimientos[i].numero_comprobante = item.num_comp;
        this.movimientos[i].origen_deposito = item.origen_deposito;
        this.movimientos[i].tipo_cheque = item.tipo_cheque;
        this.movimientos[i].tipo_movimiento = item.tipo_mov;
        this.movimientos[i].importe = item.importe;
        this.movimientos[i].tipo_movimiento_nom = this.tiposMovBanco.filter(elem => elem.tipo_mov_codigo == item.tipo_mov)[0].tipo_mov_nombre
        this.totalAcumulado = this.retornaAcuMov;
        this.pendiente = this.totalLiq - this.totalAcumulado;   this.pendiente = parseFloat(this.pendiente.toFixed(2));
        this.dialog_AM = false;
      }
    },
    actualizar_copias(){

    },
    async guardar(){
      if(roundNumber(this.retornaAcuMov,2) < roundNumber(this.totalLiq,2)){
        this.$swal.fire({
          icon: 'warning',
          title: `Confirmando Movimientos Bancarios`,
          text: `La suma de los movimientos ($${this.totalAcumulado}) no coincide con el total de la liquidación ($${this.totalLiq}). No se puede proceder.`,
        })
        return;
      }
      //valida las fechas
      this.movimientos.forEach(elem => {
        if(elem.tipo_movimiento != 3){
          elem.fecha_emision = null;
          elem.fecha_vencimiento = null;
        }
      })
      this.movimientos_a.forEach(elem => {
        if(elem.tipo_movimiento != 3){
          elem.fecha_emision = null;
          elem.fecha_vencimiento = null;
        }
      })
      this.$store.state.loading = true
      await this.$store.dispatch('conciliacionManual/enviarMovMultiples',{
        liquidacion_id: this.datos.liqId,
        movimientos: this.movimientos,
        movimientos_quitar: this.movimientos_a
      })
        .then(() => {
          this.actualizar_copias()
          this.$swal.fire({icon: 'success', title: 'Confirmando Movimientos Bancarios', text: 'Movimientos creados exitosamente.',})
          this.dialog = false;
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    }
  },
  computed:{
    dialog:{
      get(){
        return this.value
      },
      set(value){
        this.$emit('input', value)
      }
    },
    retornaAcuMov(){
      let acu = 0;
      this.movimientos.forEach(elem => acu += elem.importe );
      acu = parseFloat(acu.toFixed(2));
      //
      this.totalAcumulado = acu; parseFloat(this.totalAcumulado.toFixed(2));
      this.totalAcuCopia = parseFloat(this.totalAcumulado.toFixed(2));
      this.pendiente = this.totalLiq - this.totalAcumulado;   parseFloat(this.pendiente.toFixed(2));
      //
      return acu;
    }
  },
  components:{
    BtnConfirmarVue, SearchDataTableVue, ModalAgregarEditarMovBancVue
  },
  watch:{
    dialog(val){
      if(val){
        this.iniciarModal();
        //hacer copias del acu y del pendiente
      }else{
        this.idInterno = 0;
        this.movimientos=[];
        this.totalAcumulado = this.totalAcuCopia;
        this.pendiente = this.pendienteCopia;
        this.movimientos_a = JSON.parse(JSON.stringify(this.movimientos_aCopia));
      }
    }
  }
}
</script>

<style>

</style>